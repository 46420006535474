import Patrimoines from './patrimoines.svelte';
import Infos from './infos.svelte';
import Stats from './stats.svelte';
import Wordcloud from './wordcloud.svelte';

const components = [
  { component: Patrimoines, id: 'patrimoines', props: { name: '' } },
  { component: Infos, id: 'infos', props: {} },
  { component: Stats, id: 'stats', props: {} },
  { component: Wordcloud, id: 'wordcloud', props: {} }
];

components.forEach(({ component, id, props }) => {
  const target = document.getElementById(id);
  if (target) {
    new component({
      target,
      props
    });
  }
});
