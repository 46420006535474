<script>
  import { onMount } from 'svelte';
  import { createClient } from '@supabase/supabase-js';

  const SUPABASE_URL = 'https://supabase.ia86.cc';
  const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICJyb2xlIjogImFub24iLAogICJpc3MiOiAic3VwYWJhc2UiLAogICJpYXQiOiAxNzIxODU4NDAwLAogICJleHAiOiAxODc5NjI0ODAwCn0.7qclSYg6ubtSUs4CRS2BFSiaFBmrhmhhDB3CEgHO8s0';
  const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

  let departementInfo = null;
  const todomain = ".test.meconnu.org";
  const domainName = window.location.hostname;
  const subdomain = domainName.includes(todomain)
    ? domainName.split(todomain)[0]
    : "";

  let loading = true;

  async function fetchDepartementInfo() {
    if (subdomain) {
      const { data: departementData, error: departementError } = await supabase
        .rpc('get_department_info', { department_name: subdomain });

      if (departementError) {
        console.error("Erreur lors de la récupération des informations du département :", departementError);
      } else if (departementData && departementData.length > 0) {
        departementInfo = departementData[0];
      }
    } else {
      console.warn("Sous-domaine non défini ou vide.");
    }
    loading = false;
  }

  onMount(() => {
    fetchDepartementInfo();
  });
</script>

{#if loading}
  <div class="loading-container">
    <svg class="loading-spinner" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
    <p>Chargement des données...</p>
  </div>
{:else if departementInfo}
  <div>
    <h2>Le département "{departementInfo.dept_title}"</h2>
    <h3>Quelques informations...</h3>
    <b>Pays :</b> {departementInfo.country_title}<br>
    <b>Région :</b> {departementInfo.region_title} (code insee {departementInfo.region_insee})<br>
    <b>Surface :</b> {departementInfo.dept_surface_km2} km²<br>
    <b>Code postal :</b> {departementInfo.dept_insee}<br>
    <h3>A propos du département</h3>
    <p>Encore un département largement méconnu ! Ce site va tenter de le présenter en donnant avec précisions, toutes les traces visibles du passé et du présent.</p>
    <p>Il comporte {departementInfo.patrimoine_count} patrimoines géolocalisés illustrés par {departementInfo.document_count} documents. Ce département compte {departementInfo.total_authors} auteur(s) qui ont permis de couvrir {departementInfo.communes_with_patrimoine} communes.</p>

    {#if departementInfo.config_valeur}
      <h2>Mot de l'Administrateur</h2>
      <p>{departementInfo.config_valeur}</p>
    {/if}
  </div>
{:else if subdomain}
  <p>Aucune information trouvée pour le département : {subdomain}</p>
{/if}
