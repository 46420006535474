<script>
  import { onMount } from 'svelte';
  import { createClient } from '@supabase/supabase-js';

  const SUPABASE_URL = 'https://supabase.ia86.cc';
  const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICJyb2xlIjogImFub24iLAogICJpc3MiOiAic3VwYWJhc2UiLAogICJpYXQiOiAxNzIxODU4NDAwLAogICJleHAiOiAxODc5NjI0ODAwCn0.7qclSYg6ubtSUs4CRS2BFSiaFBmrhmhhDB3CEgHO8s0';
  const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

  const todomain = ".test.meconnu.org";
  const domainName = window.location.hostname;
  const departmentName = domainName.includes(todomain) ? domainName.split(todomain)[0] : "";

  let loading = true;
  let stats = [];
  let particularitesStats = [];
  let marcheStats = [];
  let accesStats = [];
  let timeStats = [];

  function getIcon(name, additionalClass = '') {
    const icons = {
      'Favourite': 'icofont-favourite',
      'difficile à trouver': 'icofont-ui-map',
      'payant': 'icofont-euro',
      'risque objectif': 'icofont-dead',
      'coup de coeur': 'icofont-heart-alt',
      'interdit': 'icofont-nobody',
      'Patrimoine labelisé': 'icofont-badge',
      'Chiens autorisés': 'icofont-dog-alt',
      'Interdit aux chiens': 'icofont-dog-2',
      'Architecture civile': 'icofont-eiffel',
      'Architecture religieuse': 'icofont-church',
      'Bâtiment ou Friche industriel': 'icofont-industries-5',
      'Châteaux, ouvrages militaires': 'icofont-castle',
      'Culturel et artistique': 'icofont-mask',
      'Insolite ou inclassable': 'icofont-question-circle',
      'Mines, cavités et grottes': 'icofont-bat',
      'Parc, jardin ou forêt': 'icofont-leaf',
      'Petit patrimoine': 'icofont-fontaine',
      'Pierres et carrières': 'icofont-hill',
      'Préhistoire, antiquité': 'icofont-institution',
      'Rivière lac': 'icofont-blood-drop',
      'Time': 'icofont-clock-time',
      'Marche': 'icofont-foot-print',
      'Acces': 'icofont-key'
    };
     return icons[name] ? `<i class="${icons[name]} icofont-2x ${additionalClass}"></i>` : null;
  }

function getNumRed(icon, numberRed) {
  const num = Number(numberRed);
  const validNum = isNaN(num) ? 0 : Math.max(0, Math.min(4, num)); // Limite entre 0 et 4
  let redIcons = Array(validNum).fill(`<i class="${icon} red"></i>`).join('');
  let greyIcons = Array(4 - validNum).fill(`<i class="${icon} lightgrey"></i>`).join('');
  return `${redIcons}${greyIcons}`;
}

  async function fetchStats() {
    const { data: statsData, error: statsError } = await supabase.rpc('stats_patrimoine_par_departement', { department_name: departmentName });
    if (statsError) {
      console.error('Erreur lors de la récupération des statistiques :', statsError);
    } else {
      stats = statsData;
    }

    const { data: particularitesData } = await supabase.rpc('stats_patrimoine_par_particularite', { department_name: departmentName });
    particularitesStats = particularitesData || [];

    const { data: marcheData } = await supabase.rpc('stats_patrimoine_par_marche', { department_name: departmentName });
    marcheStats = marcheData || [];

    const { data: accesData } = await supabase.rpc('stats_patrimoine_par_acces', { department_name: departmentName });
    accesStats = accesData || [];

    const { data: timeData } = await supabase.rpc('stats_patrimoine_par_time', { department_name: departmentName });
    timeStats = timeData || [];

    loading = false;
  }

  onMount(fetchStats);
</script>

{#if loading}
  <div class="loading-container">
    <svg class="loading-spinner" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
    <p>Chargement des données...</p>
  </div>
{:else}
  <!-- Premier tableau: Stats par patrimoine -->
  <table class="rtable stats-table method">
    <thead>
      <tr>
        <th width="50px">Type</th>
        {#each [1, 2, 3, 4] as interest}
          <th class="stars-header minor">
              {@html getNumRed('icofont-favourite', interest)}
          </th>
        {/each}
        <th>Totaux</th>
      </tr>
    </thead>
    <tbody>
      {#each Array.from(new Set(stats.map(s => s.patrimoine_type))).filter(Boolean).sort() as type}
        <tr>
          <td align="center" class="major">
            {#if getIcon(type)}
              {@html getIcon(type)}
            {:else}
              <small>{type}</small>
            {/if}
          </td>
          {#each [1, 2, 3, 4] as interest}
            <td align="center">
              <a href={`*trimulticritere?interet[]=${interest}&types[]=${type}`} class="red">
                {#if stats.find(stat => stat.patrimoine_type === type && stat.patrimoine_interet == interest)}
                  {stats.find(stat => stat.patrimoine_type === type && stat.patrimoine_interet == interest).total}
                {:else}
                  0
                {/if}
              </a>
            </td>
          {/each}
          <td align="center" class="column-total red">
            <a href={`*trimulticritere?types[]=${type}`}>
              {#if stats.find(stat => stat.patrimoine_type === type && stat.patrimoine_interet === null)}
                {stats.find(stat => stat.patrimoine_type === type && stat.patrimoine_interet === null).total}
              {:else}
                0
              {/if}
            </a>
          </td>
        </tr>
      {/each}
      <tr class="totaux">
        <td align="center"><b>Totaux</b></td>
        {#each [1, 2, 3, 4] as interest}
          <td align="center" class="column-total red">
            <a href={`*trimulticritere?interet[]=${interest}`}>
              {#if stats.find(stat => stat.patrimoine_type === null && stat.patrimoine_interet == interest)}
                {stats.find(stat => stat.patrimoine_type === null && stat.patrimoine_interet == interest).total}
              {:else}
                0
              {/if}
            </a>
          </td>
        {/each}
        <td align="center" class="column-total red">
          <a href="*trimulticritere">
            {#if stats.find(stat => stat.patrimoine_type === null && stat.patrimoine_interet === null)}
              {stats.find(stat => stat.patrimoine_type === null && stat.patrimoine_interet === null).total}
            {:else}
              0
            {/if}
          </a>
        </td>
      </tr>
    </tbody>
  </table>

  <br>

  <!-- Deuxième tableau: Stats par particularité -->
  <table class="rtable stats-table method">
    <thead>
      <tr>
        <th width="50px">Part.</th>
        {#each [1, 2, 3, 4] as interest}
          <th class="stars-header minor">
              {@html getNumRed('icofont-favourite', interest)}
          </th>
        {/each}
        <th>Totaux</th>
      </tr>
    </thead>
    <tbody>
      {#each Array.from(new Set(particularitesStats.map(s => s.patrimoine_particularite))).filter(Boolean).sort((a, b) => {
          const aHasIcon = getIcon(a) !== null;
          const bHasIcon = getIcon(b) !== null;
          return (aHasIcon === bHasIcon) ? 0 : (aHasIcon ? -1 : 1);
        }) as particularite}
        <tr>
          <td align="center" class="major">
            {#if getIcon(particularite)}
              {@html getIcon(particularite)}
            {:else}
              <small>{particularite}</small>
            {/if}
          </td>
          {#each [1, 2, 3, 4] as interest}
            <td align="center">
              <a href={`*trimulticritere?interet[]=${interest}&${particularite}=1`}>
                {#if particularitesStats.find(stat => stat.patrimoine_particularite === particularite && stat.patrimoine_interet == interest)}
                  {particularitesStats.find(stat => stat.patrimoine_particularite === particularite && stat.patrimoine_interet == interest).total}
                {:else}
                  0
                {/if}
              </a>
            </td>
          {/each}
          <td align="center" class="column-total">
            <a href={`*trimulticritere?${particularite}=1`}>
              {#if particularitesStats.find(stat => stat.patrimoine_particularite === particularite && stat.patrimoine_interet === null)}
                {particularitesStats.find(stat => stat.patrimoine_particularite === particularite && stat.patrimoine_interet === null).total}
              {:else}
                0
              {/if}
            </a>
          </td>
        </tr>
      {/each}
      <tr class="totaux">
        <td align="center"><b>Totaux</b></td>
        {#each [1, 2, 3, 4] as interest}
          <td align="center" class="column-total">
            <a href={`*trimulticritere?interet[]=${interest}`}>
              {#if particularitesStats.find(stat => stat.patrimoine_particularite === null && stat.patrimoine_interet == interest)}
                {particularitesStats.find(stat => stat.patrimoine_particularite === null && stat.patrimoine_interet == interest).total}
              {:else}
                0
              {/if}
            </a>
          </td>
        {/each}
        <td align="center" class="column-total">
          <a href="*trimulticritere">
            {#if particularitesStats.find(stat => stat.patrimoine_particularite === null && stat.patrimoine_interet === null)}
              {particularitesStats.find(stat => stat.patrimoine_particularite === null && stat.patrimoine_interet === null).total}
            {:else}
              0
            {/if}
          </a>
        </td>
      </tr>
    </tbody>
  </table>

  <br>

  <!-- Troisième tableau: Stats par marché -->
  <table class="rtable stats-table method">
    <thead>
      <tr>
        <th width="50px">Appr.</th>
        {#each [1, 2, 3, 4] as interest}
          <th class="stars-header minor">
              {@html getNumRed('icofont-favourite', interest)}
          </th>
        {/each}
        <th>Totaux</th>
      </tr>
    </thead>
    <tbody>
      {#each Array.from(new Set(marcheStats.map(s => s.marche))).filter(Boolean).sort() as marche}
        <tr>
          <td align="center" class="minor">
		{@html getNumRed('icofont-foot-print', marche)}
          </td>
          {#each [1, 2, 3, 4] as interest}
            <td align="center">
              <a href={`*trimulticritere?interet[]=${interest}&marche[]=${marche}`}>
                {#if marcheStats.find(stat => stat.marche === marche && stat.interet == interest)}
                  {marcheStats.find(stat => stat.marche === marche && stat.interet == interest).total}
                {:else}
                  0
                {/if}
              </a>
            </td>
          {/each}
          <td align="center" class="column-total">
            <a href={`*trimulticritere?marche[]=${marche}`}>
              {#if marcheStats.find(stat => stat.marche === marche && stat.interet === null)}
                {marcheStats.find(stat => stat.marche === marche && stat.interet === null).total}
              {:else}
                0
              {/if}
            </a>
          </td>
        </tr>
      {/each}
      <tr class="totaux">
        <td align="center"><b>Totaux</b></td>
        {#each [1, 2, 3, 4] as interest}
          <td align="center" class="column-total">
            <a href={`*trimulticritere?interet[]=${interest}`}>
              {#if marcheStats.find(stat => stat.marche === null && stat.interet == interest)}
                {marcheStats.find(stat => stat.marche === null && stat.interet == interest).total}
              {:else}
                0
              {/if}
            </a>
          </td>
        {/each}
        <td align="center" class="column-total">
          <a href="*trimulticritere">
            {#if marcheStats.find(stat => stat.marche === null && stat.interet === null)}
              {marcheStats.find(stat => stat.marche === null && stat.interet === null).total}
            {:else}
              0
            {/if}
          </a>
        </td>
      </tr>
    </tbody>
  </table>

  <br>

  <!-- Quatrième tableau: Stats par accès -->
  <table class="rtable stats-table method">
    <thead>
      <tr>
        <th width="50px">Accès</th>
        {#each [1, 2, 3, 4] as interest}
          <th class="stars-header minor">
               {@html getNumRed('icofont-favourite', interest)}
          </th>
        {/each}
        <th>Totaux</th>
      </tr>
    </thead>
    <tbody>
      {#each Array.from(new Set(accesStats.map(s => s.acces))).filter(Boolean).sort() as acces}
        <tr>
          <td align="center" class="minor">
		{@html getNumRed('icofont-key', acces)}
          </td>
          {#each [1, 2, 3, 4] as interest}
            <td align="center">
              <a href={`*trimulticritere?interet[]=${interest}&acces[]=${acces}`}>
                {#if accesStats.find(stat => stat.acces === acces && stat.interet == interest)}
                  {accesStats.find(stat => stat.acces === acces && stat.interet == interest).total}
                {:else}
                  0
                {/if}
              </a>
            </td>
          {/each}
          <td align="center" class="column-total">
            <a href={`*trimulticritere?acces[]=${acces}`}>
              {#if accesStats.find(stat => stat.acces === acces && stat.interet === null)}
                {accesStats.find(stat => stat.acces === acces && stat.interet === null).total}
              {:else}
                0
              {/if}
            </a>
          </td>
        </tr>
      {/each}
      <tr class="totaux">
        <td align="center"><b>Totaux</b></td>
        {#each [1, 2, 3, 4] as interest}
          <td align="center" class="column-total">
            <a href={`*trimulticritere?interet[]=${interest}`}>
              {#if accesStats.find(stat => stat.acces === null && stat.interet == interest)}
                {accesStats.find(stat => stat.acces === null && stat.interet == interest).total}
              {:else}
                0
              {/if}
            </a>
          </td>
        {/each}
        <td align="center" class="column-total">
          <a href="*trimulticritere">
            {#if accesStats.find(stat => stat.acces === null && stat.interet === null)}
              {accesStats.find(stat => stat.acces === null && stat.interet === null).total}
            {:else}
              0
            {/if}
          </a>
        </td>
      </tr>
    </tbody>
  </table>

  <br>

  <!-- Cinquième tableau: Stats par temps -->
  <table class="rtable stats-table method">
    <thead>
      <tr>
        <th width="50px">Duree</th>
        {#each [1, 2, 3, 4] as interest}
          <th class="stars-header minor">
             {@html getNumRed('icofont-favourite', interest)}
          </th>
        {/each}
        <th>Totaux</th>
      </tr>
    </thead>
    <tbody>
      {#each Array.from(new Set(timeStats.map(s => s.time))).filter(Boolean).sort() as time}
        <tr>
          <td align="center" class="minor">
		{@html getNumRed('icofont-clock-time', time)}
          </td>
          {#each [1, 2, 3, 4] as interest}
            <td align="center">
              <a href={`*trimulticritere?interet[]=${interest}&time[]=${time}`}>
                {#if timeStats.find(stat => stat.time === time && stat.interet == interest)}
                  {timeStats.find(stat => stat.time === time && stat.interet == interest).total}
                {:else}
                  0
                {/if}
              </a>
            </td>
          {/each}
          <td align="center" class="column-total">
            <a href={`*trimulticritere?time[]=${time}`}>
              {#if timeStats.find(stat => stat.time === time && stat.interet === null)}
                {timeStats.find(stat => stat.time === time && stat.interet === null).total}
              {:else}
                0
              {/if}
            </a>
          </td>
        </tr>
      {/each}
      <tr class="totaux">
        <td align="center"><b>Totaux</b></td>
        {#each [1, 2, 3, 4] as interest}
          <td align="center" class="column-total">
            <a href={`*trimulticritere?interet[]=${interest}`}>
              {#if timeStats.find(stat => stat.time === null && stat.interet == interest)}
                {timeStats.find(stat => stat.time === null && stat.interet == interest).total}
              {:else}
                0
              {/if}
            </a>
          </td>
        {/each}
        <td align="center" class="column-total">
          <a href="*trimulticritere">
            {#if timeStats.find(stat => stat.time === null && stat.interet === null)}
              {timeStats.find(stat => stat.time === null && stat.interet === null).total}
            {:else}
              0
            {/if}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
{/if}
