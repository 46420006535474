<script>
  import { onMount } from 'svelte';
  import { createClient } from '@supabase/supabase-js';
  import { DateTime } from 'luxon';
  import { TabulatorFull as Tabulator } from 'tabulator-tables';
  import 'tabulator-tables/dist/css/tabulator.min.css';

  const SUPABASE_URL = 'https://supabase.ia86.cc';
  const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICJyb2xlIjogImFub24iLAogICJpc3MiOiAic3VwYWJhc2UiLAogICJpYXQiOiAxNzIxODU4NDAwLAogICJleHAiOiAxODc5NjI0ODAwCn0.7qclSYg6ubtSUs4CRS2BFSiaFBmrhmhhDB3CEgHO8s0';
  const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

  const todomain = ".test.meconnu.org";
  const domainName = window.location.hostname;
  const departmentName = domainName.includes(todomain) ? domainName.split(todomain)[0] : "";

  let table;
  let tableRef;
  let tableData = [];
  let loading = true; // État de chargement
  const pageSize = 10;

  const iconMap = {
    "Indéfini": { realicon: "question-circle" },
    "Architecture civile": { realicon: "eiffel" },
    "Architecture religieuse": { realicon: "church" },
    "Bâtiment ou Friche industriel": { realicon: "industries-5" },
    "Châteaux, ouvrages militaires": { realicon: "castle" },
    "Culturel et artistique": { realicon: "mask" },
    "Insolite ou inclassable": { realicon: "question-circle" },
    "Mines, cavités et grottes": { realicon: "bat" },
    "Parc, jardin ou forêt": { realicon: "leaf" },
    "Petit patrimoine": { realicon: "fontaine" },
    "Pierres et carrières": { realicon: "hill" },
    "Préhistoire, antiquité": { realicon: "institution" },
    "Rivière lac": { realicon: "blood-drop" }
  };

  async function fetchData() {
    const { data, error } = await supabase
      .from('patrimoine')
      .select(`
        *,
        commune!inner(id, titre, departement!inner(nom))
      `)
      .eq('statut', 'publie')
      .eq('commune.departement.nom', departmentName);

    if (error) {
      console.error('Erreur lors de la récupération des données de Supabase:', error);
      loading = false;
      return;
    }

    tableData = data.map(item => ({
      ...item,
      commune_titre: item.commune ? item.commune.titre : "Inconnue" // Valeur par défaut si `commune` est null
    }));
    initTable();
    loading = false; // Fin du chargement
  }

  function typeFormatter(cell) {
    const typeName = cell.getValue();
    const iconData = iconMap[typeName];
    if (iconData) {
      return `<i title="${typeName}" class="icofont-${iconData.realicon} icofont-15x"></i>`;
    }
    return typeName;
  }

  function communeFormatter(cell) {
    const name = cell.getValue();
    return `<i title="${name}" class="icofont-home icofont-15x"></i> ${name}`;
  }

  function starFormatter(cell) {
    const rating = cell.getValue();
    let stars = "";
    for (let i = 0; i < 4; i++) {
      stars += `<i class="icofont-favourite icofont-15x ${i < rating ? 'selected' : 'grey'}"></i>`;
    }
    return stars;
  }

  function timeFormatter(cell) {
    const rating = cell.getValue();
    let stars = "";
    for (let i = 0; i < 4; i++) {
      stars += `<i class="icofont-clock-time icofont-15x ${i < rating ? 'selected' : 'grey'}"></i>`;
    }
    return stars;
  }

  function footFormatter(cell) {
    const rating = cell.getValue();
    let stars = "";
    for (let i = 0; i < 4; i++) {
      stars += `<i class="icofont-foot-print icofont-15x ${i < rating ? 'selected' : 'grey'}"></i>`;
    }
    return stars;
  }

  function lockFormatter(cell) {
    const rating = cell.getValue();
    let stars = "";
    for (let i = 0; i < 4; i++) {
      stars += `<i class="icofont-key icofont-15x ${i < rating ? 'selected' : 'grey'}"></i>`;
    }
    return stars;
  }

  function initTable() {
    if (tableRef) {
      table = new Tabulator(tableRef, {
        data: tableData,
        responsiveLayout: "hide",
        layout: "fitColumns",
        columns: [
          { title: "Type", field: "type", formatter: typeFormatter, hozAlign: "center", width: 55, responsive: 0 },
          { title: "Titre", field: "titre", responsive: 0, widthGrow: 10 },
          { title: "Intérêt", field: "interet", formatter: starFormatter, width: 170, hozAlign: "center", responsive: 0 },
          { title: "Durée", field: "time", formatter: timeFormatter, width: 170, hozAlign: "center", responsive: 1 },
          { title: "Approche", field: "marche", formatter: footFormatter, width: 170, hozAlign: "center", responsive: 2 },
          { title: "Accès", field: "acces", formatter: lockFormatter, width: 170, hozAlign: "center", responsive: 3 },
          { title: "Commune", field: "commune_titre", widthGrow: 2, formatter: communeFormatter, responsive: 4 },
        ],
        pagination: "local",
        paginationSizeSelector: [10, 25, 50, 75, 100],
        paginationSize: pageSize,
        locale: "fr-fr",
        langs: {
          "fr-fr": {
            pagination: {
              first: "Premier",
              first_title: "Première Page",
              last: "Dernier",
              last_title: "Dernière Page",
              prev: "Précédent",
              prev_title: "Page Précédente",
              next: "Suivant",
              next_title: "Page Suivante",
              all: "Tout",
              "Page Size": "Patrimoines"
            }
          }
        }
      });
    }
  }

  onMount(() => {
    fetchData();
  });
</script>

{#if loading}
  <div class="loading-container">
    <svg class="loading-spinner" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
    <p>Chargement des données...</p>
  </div>
{:else}
  <div bind:this={tableRef} class="tabulator"></div>
{/if}

<style>
  .tabulator {
    width: 100%;
    border: 1px solid #ddd;
    font-family: Arial, sans-serif;
  }

  :global(.tabulator .tabulator-cell[tabulator-field="titre"]) {
    font-size: 14px;
  }

  :global(.icofont-favourite.selected),
  :global(.icofont-clock-time.selected),
  :global(.icofont-foot-print.selected),
  :global(.icofont-key.selected) {
    color: #BF2C2C;
  }

  :global(.icofont-favourite.grey),
  :global(.icofont-clock-time.grey),
  :global(.icofont-foot-print.grey),
  :global(.icofont-key.grey) {
    color: lightgrey;
  }
</style>

<div bind:this={tableRef} class="tabulator"></div>
