<script>
  import WordCloud from "svelte-d3-cloud";
  import { createClient } from '@supabase/supabase-js';

  const SUPABASE_URL = 'https://supabase.ia86.cc';
  const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICJyb2xlIjogImFub24iLAogICJpc3MiOiAic3VwYWJhc2UiLAogICJpYXQiOiAxNzIxODU4NDAwLAogICJleHAiOiAxODc5NjI0ODAwCn0.7qclSYg6ubtSUs4CRS2BFSiaFBmrhmhhDB3CEgHO8s0';
  const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

  let words = [];
  let hasData = false;

  async function fetchWordCounts() {
    const { data, error } = await supabase.rpc('get_word_counts');

    if (error) {
      console.error("Erreur lors de la récupération des données:", error);
    } else {
      words = data.map(item => ({
        text: item.word,
        count: item.count,
        url: `+-${item.word}-+` // Formatage de l'URL
      }));
      hasData = words.length > 0;
    }
  }

  fetchWordCounts();

  // Fonction pour déterminer l’angle d’orientation des mots
  function randomAngle() {
    const angles = [0, 30, -30, 45, -45];
    return angles[Math.floor(Math.random() * angles.length)];
  }
</script>

{#if hasData}
  <div>
    <WordCloud
      words={words}
      rotate={randomAngle}
      fontSize={(word) => word.count * 2}
      on:wordclick={(e) => window.open(e.detail.url, '_blank')}
    />
  </div>
{:else}
  <p>Aucun mot clé à afficher.</p>
{/if}